import { PageLayout } from "@components/index";
import React from "react";
import * as styles from "./index.module.scss";
import { Hero } from "@components/LimsSoftware/Hero";
import GENEMOD_FREEZER from "@assets/products/genemod_ELN_v6.mp4";
import { Colors } from "@helpers/Colors";
import { ELN } from "@components/ElnSoftware/eln";
import { CentralProtocolHub } from "@components/ElnSoftware/CentralProtocolHub";
import { LiveEditing } from "@components/ElnSoftware/LiveEditing";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import { ComprehensivePM } from "@components/ElnSoftware/ComprehensivePM";
import { ModernELN } from "@components/ElnSoftware/ModernELN";
import { Industries } from "pages/ads/_components/_Industries/_Industries";
import { SectionLearnMore } from "@components/ElnSoftware/SectionLearnMore";
import { Faqs } from "@components/LimsSoftware/Faqs";

type HeroProps = {
	title: string;
	sub_title: string;
	video_src: string;
	text: string;
	containerOverwrite?: string;
	heroWrapperOverride?: string;
	autoplay: boolean;
	bodyColor?: Colors;
};

export default function ELNSoftwarePage() {
	const heroProps: HeroProps = {
		sub_title: "Products",
		title: "Electronic Lab Notebook (ELN)",
		video_src: GENEMOD_FREEZER,
		text: "Transform your laboratory’s productivity with Genemod’s Electronic Lab Notebook (ELN). Improve the management of experiments, research data, protocols, and all the essential lab documentation.",
		containerOverwrite: styles.heroContainer,
		heroWrapperOverride: styles.heroWrapperOverride,
		autoplay: true,
		bodyColor:'text-helper'
	};
	const industriesSectionProps = {
		tabsGap: styles.tabsGap,
		industriesWrapper: styles.industriesWrapper,
		overrideFirstImage: styles.overrideFirstImage,
		overrideSecondImage: styles.overrideSecondImage,
		overrideImagesCard: styles.overrideImagesCard,
		justTitleAndContentOverwrite: styles.justTitleAndContentOverwrite,
	};
	const faqs = [
		{
			question:
				"What is laboratory information management systems (LIMS)?",
			children: [
				`Laboratory Information Management Systems (LIMS) are sophisticated software applications designed to streamline laboratory operations by managing data, automating workflows, and ensuring compliance with regulatory standards.`,
			],
			id: "1",
		},
		
		{
			question: "How can LIMS and ELN benefit my research?",
			children: [
				`The integration of LIMS and ELN facilitates a seamless research workflow, reducing manual errors, improving data traceability, and saving valuable time. With comprehensive LIMS and ELN solutions, researchers can focus on discovery and innovation, knowing that their data management is in expert hands.`,
			],
			id: "2",
		},
		{
			question: "What is electronic lab notebook (ELN)?",
			children: [
				`Electronic Lab Notebooks (ELN) digitize the traditional lab notebook, offering an organized, searchable, and secure way to document experiments. ELN software not only captures your research with precision but also enhances collaboration across your team, bringing a new level of innovation and productivity to your lab's workbench.`,
			],
			id: "3",
		},
		{
			question:
				"What types of laboratories can benefit from using LIMS software?",
			children: [
				`Laboratories involved in scientific research can effectively utilize LIMS software. These include biopharmaceutical, industrial biotech, clinical research, biomanufacturing, biorepositories, contract services, and more. Labs of all sizes, from small startups to large enterprises, can utilize LIMS to enhance their operations, improve data management, streamline workflows, and ensure regulatory compliance.`,
			],
			id: "4",
		},
		{
			question:
				"What is the difference between cloud-based LIMS and legacy LIMS software?",
			children: [
				`Cloud-based LIMS offers convenient accessibility from any location, improving collaboration and productivity. It scales easily with the growing needs of the lab, and data storage and security are managed by the provider, ensuring higher levels of data protection and regular backups. In contrast, legacy LIMS often requires significant on-premise infrastructure, higher upfront investment, and dedicated IT support for maintenance and updates. Collaboration can also be limited to the physical location of the legacy system, making cloud-based solutions more flexible and efficient for modern labs.`,
			],
			id: "5",
		},
		{
			question: "What advantage does Genemod’s LIMS software offer?",
			children: [
				`Genemod's LIMS is engineered to be the central hub for laboratory management, integrating various functionalities such as inventory tracking, electronic lab notebooks, equipment scheduling, order management, and many other scientific research features into a unified platform. This integration facilitates a seamless, efficient workflow, enabling scientists to concentrate on their core research objectives with fewer administrative burdens.`,
				`Key benefits include:`,
				<ul>
					<li>
						<b>Streamlined operations: </b>Our platform automates
						and simplifies routine tasks, allowing for more focused
						research time and less administrative overhead.
					</li>
					<li>
						<b>Budget optimization: </b>The all-in-one nature of
						Genemod’s LIMS reduces the need for multiple software
						systems, which can translate into considerable cost
						savings.
					</li>
					<li>
						<b>Collaborative environment: </b>Real-time data sharing
						and management features promote effective team
						collaboration, regardless of physical location.
					</li>
					<li>
						<b>Data integrity and compliance: </b>Maintain high data
						quality with secure, compliant, and traceable data
						management capabilities.
					</li>
					<li>
						<b>Operational oversight: </b>Gain insights into your
						lab's productivity with advanced analytics and
						reporting, supporting strategic decisions and continuous
						improvement.
					</li>
				</ul>,
			],
			id: "6",
		},
		{
			question:
				"Can Genemod’s LIMS software be customized for my specific needs?",
			children: [
				`Absolutely. At Genemod, we understand that each laboratory's needs are unique. Our flexible LIMS platforms are designed to adapt to your specific research requirements, offering customizability that ensures a perfect fit for your workflow.`,
			],
			id: "6",
		},
		{
			question:
				"What distinguishes Genemod’s LIMS & ELN from other solutions on the market?",
			children: [
				`Genemod's LIMS stands out for their intuitive integration, scalability, and personalized support. While other systems manage data and workflows, Genemod goes further by providing a seamless, user-friendly experience that adapts to your lab's evolving needs.`,
				`Distinctive features include:`,
				<ul>
					<li>
						<b>Dynamic LIMS products: </b>Genemod LIMS is a comprehensive platform with virtual freezers, consumable management, order management, electronic lab notebook (ELN), lab equipment scheduler, and many other features for scientific research and lab management. 
					</li>
					<li>
						<b>Intuitive design: </b>Genemod LIMS platform is created with the user experience at the forefront, ensuring that scientists can intuitively navigate and utilize the system with minimal training. 
					</li>
					<li>
						<b>Advanced integration: </b>Genemod offers a truly interconnected system where data flows effortlessly between LIMS and ELN, providing a cohesive ecosystem for all your laboratory data management needs. 
					</li>
					<li>
						<b>Customizable framework: </b>Genemod is highly customizable to align with your specific research processes and goals. 
					</li>
					<li>
						<b>Scalable architecture: </b>Genemod is built to scale with your laboratory's growth, ensuring you have a robust system at every stage of your expansion.
					</li>
					<li>
						<b>Dedicated support: </b>Our global team of scientists provide unmatched support, bringing their expertise directly to your lab's unique challenges.
					</li>
				</ul>,
				`By choosing Genemod, you're not just selecting software; you're adopting a partnership that propels your lab's efficiency, data management, and innovation potential to new heights.`
			],
			id: "7",
		},
		
	];
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "eln-software" }}
			defaultHeader="light"
		>
			<div className={styles.pageWrapper}>
				<Hero {...heroProps} />
				<ELN />
				<CentralProtocolHub />
				<LiveEditing />
				<div className={styles.ourPartners}>
					<PartnersV2 />
				</div>
				<ComprehensivePM />
				<ModernELN />
				<Industries {...industriesSectionProps} />
				<SectionLearnMore />
				<Faqs title="Frequently asked questions" faqs={faqs} />
			</div>
		</PageLayout>
	);
}
